<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/15 23:59
     Remark: 示例3
     */
    // 引入组件
    import DrawerExample3 from '../../../components/drawer/example3/example3.vue';

    export default {
        // 注册组件
        components: {
            DrawerExample3
        },
        data() {
            return {
                // 定义控制抽屉显示的变量
                drawer_visible: false
            }
        },
        mounted() {
        },
        methods: {
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            }
        }
    }
</script>
<template>
    <div>
        <div>
            <!--打开抽屉的按钮-->
            <a-button type="primary" @click="showDrawer">打开抽屉</a-button>
            <!--:close="onCloseDrawer"      给 组件 定义关闭函数-->
            <!--:visible="drawer_visible"   给 组件 定义控制显示的变量-->
            <DrawerExample3 :close="onCloseDrawer" :visible="drawer_visible"></DrawerExample3>
        </div>
    </div>
</template>
<style scoped>

</style>
