<script>
/***
 Name: example3
 Code: sa0ChunLuyu
 Time: 2021/10/16 09:47
 Remark: 编辑抽屉示例的抽屉组件
 */
export default {
  // 接收 显示变量 和 关闭函数
  props: ['visible', 'close'],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    // 定义 组件 自身的关闭函数
    onClose() {
      this.close();
    },
  }
}
</script>
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="这个是标题" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>这里写实际的内容</div>
    </a-drawer>
  </div>
</template>
<style scoped>

</style>
